@import url(//fonts.googleapis.com/css?family=Lato:300:400);
@-webkit-keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes modal-video-inner {
  from {
    -webkit-transform: translate(0, 100px);
            transform: translate(0, 100px);
  }
  to {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video;
          animation-name: modal-video;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
          transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modal-video-inner;
          animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}
.modal-video-close-btn:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.modal-video-close-btn:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.modal-video-close-btn:before, .modal-video-close-btn:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}/*# sourceMappingURL=video.css.map */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/outfit/v6/QGYvz_MVcBeNP4NJtEtqUYLknw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  font-family: "Outfit", sans-serif;
  color: #fff;
  overflow-x: hidden;
  margin: 0;
}

a {
  color: initial;
  text-decoration: none;
}

.loader {
  background-color: #00587E;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100vh;
}

.header {
  width: 100%;
  height: 10rem;
  background-color: #33A2C5;
  position: relative;
}
.header .header__inner {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 1000;
}
.header .header__inner .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 10rem;
  height: 4rem;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
}
.header .header__inner .nav .logo-container img {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}
.header .header__inner .nav .nav__lists {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .header__inner .nav .nav__lists .nav__list {
  list-style: none;
  padding: 0 1.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.header .header__inner .nav .nav__lists .nav__link {
  cursor: pointer;
  color: #eeeeee;
  transition: 0.2s all ease;
}
.header .header__inner .nav .nav__lists .nav__link:hover {
  color: #00587E;
}
.header .header__inner .nav .nav__lists .nav__link:focus {
  color: #00587E;
}
.header .header__inner .nav .button-container .nav-btn {
  width: 15rem;
  height: 5rem;
  padding: 1.7rem 2.5rem;
  border-radius: 1rem;
  background: #00587E;
  color: #ffffff;
  font-family: inherit;
  font-size: 1.6rem;
  transition: 0.2s all ease;
}
.header .header__inner .nav .button-container .nav-btn:hover {
  background-color: #2C3131;
}
.header .header__inner .nav .button-container .nav-btn:focus {
  background-color: #2C3131;
}
.header .header__inner .mobile__nav {
  position: relative;
  display: none;
  z-index: 99;
}
.header .header__inner .mobile__nav .hamburger {
  cursor: pointer;
  z-index: 999;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  width: 4rem;
  height: 4rem;
}
.header .header__inner .mobile__nav .hamburger span {
  width: 2.5rem;
  height: 0.2rem;
  display: block;
  background-color: #eeeeee;
  transition: all 0.3s ease-in-out;
}
.header .header__inner .mobile__nav .hamburger span:first-child {
  margin-bottom: 1rem;
}
.header .header__inner .mobile__nav .hamburger.active span {
  background-color: #fff;
  position: fixed;
  right: 3rem;
}
.header .header__inner .mobile__nav .hamburger.active span:nth-child(1) {
  -webkit-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
}
.header .header__inner .mobile__nav .hamburger.active span:nth-child(2) {
  -webkit-transform: translateY(-4px) rotate(45deg);
          transform: translateY(-4px) rotate(45deg);
}
.header .header__inner .mobile__nav .nav-contents {
  width: 100%;
  height: 100vh;
  background-color: #209ac0;
  position: fixed;
  z-index: 999;
  transition: all 0.3s ease-out;
  display: block;
  top: 0;
  left: -100%;
  right: 0;
  visibility: hidden;
  opacity: 1;
}
.header .header__inner .mobile__nav .nav-contents .mobile-ham-logo {
  padding: 3rem 3rem;
}
.header .header__inner .mobile__nav .nav-contents ul {
  position: relative;
  top: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 5rem;
}
.header .header__inner .mobile__nav .nav-contents ul .nav-active a {
  color: #045d83;
}
.header .header__inner .mobile__nav .nav-contents ul .mobile__list {
  margin: 2rem 0;
  list-style-type: none;
}
.header .header__inner .mobile__nav .nav-contents ul .mobile__list .mobile__link {
  color: #fff;
  font-size: 2.5rem;
  transition: 0.2s all ease;
  cursor: pointer;
}
.header .header__inner .mobile__nav .nav-contents ul .mobile__list .mobile__link:hover {
  color: #045d83;
}
.header .header__inner .mobile__nav .nav-contents ul .mobile__list .mobile__link:focus {
  color: #045d83;
}
.header .header__inner .mobile__nav .nav-contents .mobile-ham-btn {
  margin-top: 7.5rem;
  position: absolute;
  margin-left: 5rem;
  transition: 0.2s all ease;
}
.header .header__inner .mobile__nav .nav-contents .mobile-ham-btn:hover {
  background-color: #D8B951;
}
.header .header__inner .mobile__nav .nav-contents .mobile-ham-btn:focus {
  background-color: #D8B951;
}
.header .header__inner .mobile__nav .activate {
  visibility: visible;
  left: 0;
}
.header .hero-section {
  position: relative;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  height: 80%;
  margin: 0 auto;
  flex-direction: column;
  z-index: 3;
  overflow: hidden;
}

.modal--set {
  cursor: pointer;
  display: block;
}

.nav-dark {
  background-color: #2C3131;
}

@media only screen and (max-width: 75em) {
  .header {
    width: 100%;
    height: 10vh;
    position: relative;
  }
  .header .header__inner .nav {
    padding: 3rem 5rem;
  }
  .header .header__inner .nav .nav__lists {
    display: none;
  }
  .header .header__inner .nav .button-container {
    display: none;
  }
  .header .header__inner .mobile__nav {
    display: block;
  }
  .header .header__inner .mobile__nav .hamburger.active span {
    right: 5rem;
  }
  .header .header__inner .mobile__nav .nav-contents .mobile-ham-btn {
    margin-top: 14rem;
  }
}
@media only screen and (min-width: 93.75em) {
  .header .header__inner .nav {
    padding: 3rem 15rem;
  }
  .header .header__inner .nav .nav__lists .nav__list {
    font-size: 2.2rem;
    padding: 0 2rem;
  }
  .header .header__inner .nav .button-container .nav-btn {
    font-size: 2.2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .header .header__inner .nav {
    padding: 3rem 3rem;
  }
  .header .header__inner .mobile__nav .hamburger.active span {
    right: 3rem;
  }
  .header .header__inner .mobile__nav .nav-contents .mobile-ham-btn {
    margin-top: 10rem;
  }
  .header .hero-section {
    margin: 0 auto;
  }
}
.hero {
  width: 100%;
  height: 100vh;
  background-color: #33A1C4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hero-inner {
  height: 85vh;
}
.hero-inner div {
  text-align: center;
}
.hero-inner div h2,
.hero-inner div p {
  text-align: left;
  margin: 3.5rem 0;
}
.hero-inner div .hero-content-button {
  margin: 3.5rem 0;
  text-align: left;
}
.hero-inner .hero-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-inner .hero-text div {
  max-width: 47.625rem;
}
.hero-inner .hero-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-header {
  font-size: 4.5rem;
  line-height: 5rem;
  color: #FAFAFA;
  -webkit-animation-delay: 0.12s;
          animation-delay: 0.12s;
}
.hero-header span {
  color: #00587E;
}

.hero-content {
  font-size: 1.75rem;
  line-height: 2rem;
  -webkit-animation-delay: 0.22s;
          animation-delay: 0.22s;
  color: #FAFAFA;
}

.hero-content-button .app-button {
  width: 12.5rem;
  height: 4.25rem;
  background: #33A1C4;
  border: 1px solid #FAFAFA;
  border-radius: 6px;
  color: #FAFAFA;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-right: 1.75rem;
}
.hero-content-button .play-button {
  width: 12.5rem;
  height: 4.25rem;
  background: #FAFAFA;
  border: 1px solid #FAFAFA;
  border-radius: 6px;
  color: #33A2C5;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@media only screen and (min-width: 93.75em) {
  .hero-inner .hero-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-inner .hero-text div {
    max-width: 60rem;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media only screen and (max-width: 75em) {
  .hero {
    height: 100%;
  }
  .hero img {
    width: 75%;
    padding: 50px;
  }
  .hero-inner {
    height: 100%;
    --bs-gutter-x: 0;
  }
  .hero-inner .hero-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hero-inner .hero-text div {
    text-align: center;
  }
  .hero-inner .hero-text div h2,
.hero-inner .hero-text div p {
    text-align: center;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
.section-one {
  width: 100%;
  background-color: #EAFAFF;
}

.one-inner {
  min-height: 50vh;
}
.one-inner div {
  text-align: center;
}
.one-inner div h2,
.one-inner div p {
  text-align: left;
  margin: 3.5rem 0;
}
.one-inner .one-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5rem;
}
.one-inner .one-text div {
  max-width: 47.625rem;
}
.one-inner .one-img {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.one-inner .one-img img {
  width: 55rem;
}

.one-header {
  font-size: 4.5rem;
  line-height: 5rem;
  color: #00587E;
}

.one-content {
  font-size: 1.75rem;
  line-height: 2rem;
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .one-inner .one-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .one-inner .one-text div {
    max-width: 60rem;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media only screen and (max-width: 75em) {
  .one-inner {
    height: 100%;
    padding: 10rem 10px;
  }
  .one-inner .one-text {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .one-inner .one-text div {
    text-align: center;
  }
  .one-inner .one-text div h2,
.one-inner .one-text div p {
    text-align: center;
  }
  .one-inner .one-img {
    padding-left: 0;
  }
  .one-inner .one-img img {
    width: 75%;
  }
  .one-header {
    font-size: 3rem;
    line-height: 4.5rem;
    text-align: center;
  }
  .one-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .one-content-button button, .one-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
.section-two {
  width: 100%;
  background-color: #EAFAFF;
}

.two-inner {
  background: #EAFAFF url(/static/media/two-img.23c04a2d.svg) top left no-repeat;
  background-size: cover;
  min-height: 40vh;
}
.two-inner .two-text {
  padding-left: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.two-inner .two-text div {
  max-width: 90rem;
}
.two-inner .two-text div .two-header {
  font-size: 3.5rem;
  line-height: 5rem;
  text-align: left;
}

.two-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.two-button button, .two-button a {
  width: 25rem;
  height: 6.5rem;
  font-size: 2.5rem;
  line-height: 2.5rem;
  background: #FAFAFA;
  border-radius: 10px;
  color: #00587E;
  border: 1px solid #FAFAFA;
}
.two-button button .img, .two-button a .img {
  background: url(/static/media/arr-img.ab0cf9f7.svg) no-repeat center center/16px;
  width: 75px;
  height: 75px;
  cursor: pointer;
}
.two-button .app-button {
  background: #33A1C4;
  border-radius: 6px;
  color: #FAFAFA;
  margin-right: 1.75rem;
}
.two-button .play-button {
  background: #FAFAFA;
  border-radius: 6px;
  color: #33A2C5;
}

.two-header {
  font-size: 3.5rem;
  line-height: 5rem;
  text-align: left;
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .two-inner .two-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-inner .two-text div {
    max-width: 90rem;
  }
  .two-inner .two-text div .two-header {
    font-size: 3.5rem;
    line-height: 5rem;
    text-align: left;
  }
  .two-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-button button, .two-button a {
    width: 25rem;
    height: 6.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    background: #FAFAFA;
    border-radius: 10px;
    color: #00587E;
    border: 1px solid #FAFAFA;
  }
  .two-button button .img, .two-button a .img {
    background: url(/static/media/arr-img.ab0cf9f7.svg) no-repeat center center/16px;
    width: 75px;
    height: 75px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 75em) {
  .two-inner {
    background: #EAFAFF url(/static/media/two-img.23c04a2d.svg) top left no-repeat;
    background-size: cover;
    min-height: 30vh;
    padding: 10px 10px;
  }
  .two-inner .two-text {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-inner .two-text div {
    max-width: 100%;
  }
  .two-inner .two-text div .two-header {
    font-size: 2.5rem;
    line-height: 3rem;
    text-align: center;
  }
  .two-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-button button, .two-button a {
    font-size: 1.5rem;
    line-height: 1.75rem;
    width: 15rem;
    height: 4.5rem;
    border: none;
  }
}
.section-three {
  min-height: 60vh;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-three .three-inner {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-three .three-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-three .three-header {
  font-size: 4.5rem;
  line-height: 5rem;
  padding-bottom: 2.5rem;
  color: #FAFAFA;
  color: #00587E;
  text-align: center;
}
.section-three .three-content {
  font-size: 1.75rem;
  line-height: 2rem;
  max-width: 50%;
  color: #00587E;
  text-align: center;
}
.section-three .three-img .logos-col {
  justify-content: space-between;
}
.section-three .three-img .logos {
  opacity: 0.5;
  max-width: 20rem;
  transition: 0.2s all ease;
}
.section-three .three-img .logos:hover {
  opacity: 1;
  max-width: 20rem;
}

@media only screen and (min-width: 93.75em) {
  .section-three .three-content {
    font-size: 1.75rem;
    line-height: 2rem;
    max-width: 50%;
    color: #00587E;
    text-align: center;
  }
}
@media only screen and (max-width: 75em) {
  .section-three .three-content {
    font-size: 1.75rem;
    line-height: 2rem;
    max-width: 70%;
    color: #00587E;
    text-align: center;
  }
  .section-three .three-img .logos-col {
    justify-content: center;
  }
  .section-three .three-img .logos-col div {
    display: flex;
    justify-content: center;
  }
}
.section-four {
  width: 100%;
  background-color: #EAFAFF;
}

.four-inner {
  min-height: 80vh;
}
.four-inner div {
  text-align: center;
}
.four-inner div h2,
.four-inner div p {
  text-align: left;
  margin: 3.5rem 0;
}
.four-inner .four-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5rem;
}
.four-inner .four-text div {
  max-width: 55rem;
}
.four-inner .four-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.four-inner .four-img img {
  width: 85rem;
}

.four-header {
  font-size: 4.5rem;
  line-height: 5rem;
  color: #00587E;
}

.four-content {
  font-size: 1.75rem;
  line-height: 2rem;
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .four-inner .four-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .four-inner .four-text div {
    max-width: 60rem;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media only screen and (max-width: 75em) {
  .four-inner {
    padding: 10rem 0;
  }
  .four-inner .four-img img {
    width: 100%;
  }
  .four-inner .four-text {
    padding-left: 0;
    padding-top: 5rem;
  }
  .four-inner .four-text div {
    max-width: 80%;
    text-align: center;
  }
  .four-inner .four-text div .four-header {
    font-size: 3rem;
    line-height: 4.5rem;
    text-align: center;
  }
  .four-inner .four-text div .four-content {
    font-size: 2rem;
    line-height: 3rem;
    text-align: center;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media only screen and (max-width: 33.5em) {
  .modal {
    padding: 25px 0;
  }
  .hero-header {
    width: 100%;
  }
  .hero-content {
    width: 100%;
  }
}
.section-five {
  min-height: 80vh;
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
}
.section-five .five-inner {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-five .five-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-five .five-header {
  font-size: 4.5rem;
  line-height: 5rem;
  padding-bottom: 2.5rem;
  color: #FAFAFA;
  color: #00587E;
  text-align: center;
}
.section-five .five-content {
  font-size: 1.75rem;
  line-height: 2rem;
  max-width: 50%;
  color: #00587E;
  text-align: center;
}
.section-five .five-img {
  width: 100%;
  justify-content: center;
  padding-top: 10rem;
}
.section-five .five-img .exp-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-five .five-img .exp-item img {
  max-width: 20%;
}
.section-five .five-img .exp-item div {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 60%;
  text-align: center;
}
.section-five .five-img .exp-item div h5 {
  font-size: 3.5rem;
  line-height: 5rem;
  color: #00587E;
}
.section-five .five-img .exp-item div p {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #00587E;
}

@media only screen and (min-width: 768px) {
  .five-img {
    width: 100%;
    justify-content: center;
    padding-top: 10rem;
  }
  .five-img .exp-item {
    width: 32%;
  }
}
@media only screen and (min-width: 93.75em) {
  .section-five .five-content {
    font-size: 1.75rem;
    line-height: 2rem;
    max-width: 50%;
    color: #00587E;
    text-align: center;
  }
}
@media only screen and (max-width: 75em) {
  .section-five .five-content {
    font-size: 1.75rem;
    line-height: 2rem;
    max-width: 70%;
    color: #00587E;
    text-align: center;
  }
  .section-five .five-img div {
    padding-bottom: 5rem;
  }
}
.section-six {
  width: 100%;
  background-color: #EAFAFF;
}

.six-inner {
  background: #EAFAFF url(/static/media/two-img.23c04a2d.svg) top left no-repeat;
  background-size: cover;
  min-height: 30vh;
}
.six-inner .two-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.six-inner .two-text div {
  max-width: 90rem;
}
.six-inner .two-text div .two-header {
  font-size: 3.5rem;
  line-height: 5rem;
  text-align: left;
}

.two-header {
  font-size: 3.5rem;
  line-height: 5rem;
  text-align: left;
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .two-inner .two-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-inner .two-text div {
    max-width: 90rem;
  }
  .two-inner .two-text div .two-header {
    font-size: 3.5rem;
    line-height: 5rem;
    text-align: left;
  }
  .two-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .two-button button, .two-button a {
    width: 25rem;
    height: 6.5rem;
    font-size: 2.5rem;
    line-height: 2.5rem;
    background: #FAFAFA;
    border-radius: 10px;
    color: #00587E;
    border: 1px solid #FAFAFA;
  }
  .two-button button .img, .two-button a .img {
    background: url(/static/media/arr-img.ab0cf9f7.svg) no-repeat center center/16px;
    width: 75px;
    height: 75px;
    cursor: pointer;
  }
}
.section-seven {
  min-height: 80vh;
  width: 100%;
  background-color: #EAFAFF;
}

.seven-inner {
  padding: 10rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seven-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seven-header {
  font-size: 4.5rem;
  line-height: 5rem;
  padding-bottom: 2.5rem;
  color: #FAFAFA;
  color: #00587E;
  text-align: center;
}

.five-img {
  width: 100%;
  justify-content: center;
  padding-top: 10rem;
}
.five-img .exp-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.five-img .exp-item div {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  text-align: center;
}
.five-img .exp-item div h5 {
  font-size: 3.5rem;
  line-height: 5rem;
  color: #00587E;
}
.five-img .exp-item div p {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #00587E;
}

.seven-inner {
  min-height: 80vh;
}
.seven-inner div {
  text-align: center;
}
.seven-inner div h2,
.seven-inner div p {
  text-align: left;
  margin: 3.5rem 0;
}
.seven-inner .seven-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5rem;
}
.seven-inner .seven-text div {
  max-width: 55rem;
}
.seven-inner .seven-img {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.seven-inner .seven-img .accordion-item {
  background-color: rgba(51, 161, 196, 0);
  border: none;
}
.seven-inner .seven-img .accordion-item .accordion-button:not(.collapsed) {
  background-color: #fafafa;
  color: #02597e;
}
.seven-inner .seven-img .accordion-item .accordion-button {
  font-size: 1.5rem;
  line-height: 5rem;
}
.seven-inner .seven-img .accordion-item .accordion-header {
  margin: 0.5rem 0;
  font-size: 1.75rem;
  line-height: 2rem;
}
.seven-inner .seven-img .accordion-item .accordion-header {
  font-size: 2.5rem;
  line-height: 1.75rem;
}
.seven-inner .seven-img .accordion-item .accordion-body {
  font-size: 1.5rem;
}
.seven-inner .seven-img div {
  max-width: 50rem;
}

.seven-content {
  font-size: 1.75rem;
  line-height: 2rem;
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .seven-inner .seven-text {
    padding-left: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .seven-inner .seven-text div {
    max-width: 60rem;
  }
  .hero-header {
    font-size: 5.5rem;
    line-height: 6.5rem;
  }
  .hero-content {
    font-size: 2rem;
    line-height: 3rem;
  }
  .hero-content-button button, .hero-content-button a {
    width: 12.5rem;
    height: 4.25rem;
    font-size: 2rem;
    line-height: 2.75rem;
  }
}
@media only screen and (max-width: 75em) {
  .seven-inner .seven-text {
    padding-left: 0;
  }
  .seven-inner .seven-img div {
    max-width: 97.5%;
  }
}
.modal {
  display: none;
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #00587E;
}
.modal .modal--inner .modal--content {
  overflow-y: auto;
  text-align: center;
  position: relative;
  z-index: 10000;
}
.modal .modal--inner .modal--content .modal--cancel {
  position: absolute;
  top: -3rem;
  padding: 3rem 0;
  cursor: pointer;
  right: -5rem;
}
.modal .modal--inner .modal--content .modal--cancel span:nth-child(2) {
  -webkit-transform: translateY(-1px) rotate(45deg);
          transform: translateY(-1px) rotate(45deg);
}
.modal .modal--inner .modal--content .qr_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 2rem;
  color: #2C3131;
  position: relative;
}

@media only screen and (max-width: 65em) {
  .form .form--div {
    width: 100%;
  }
}
@media only screen and (max-width: 75em) {
  .modal {
    display: none;
  }
}
@media only screen and (min-width: 93.75em) {
  .modal .modal--inner .modal--content .modal--cancel {
    right: -7rem;
  }
  .modal .modal--inner .modal--content .modal--cancel .modal .modal--inner .modal--content .modal--cancel span {
    width: 3.4rem;
    height: 0.3rem;
  }
  .modal .modal--inner .modal--content .modal--cancel .modal .modal--inner .modal--content .modal--cancel span .modal .modal--inner .modal--content .qr_container {
    width: 100%;
    height: 100%;
  }
  .modal .modal--inner .modal--content .modal--cancel .modal .modal--inner .modal--content .modal--cancel span .modal .modal--inner .modal--content .qr_container .modal .modal--inner .modal--content .form-box .qr-error {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  .modal .modal--inner .modal--content .modal--cancel .modal .modal--inner .modal--content .modal--cancel span .modal .modal--inner .modal--content .qr_container .modal .modal--inner .modal--content .qr-info {
    font-size: 3rem;
    margin: 3rem 0;
    text-align: center;
  }
  .modal .modal--inner .modal--content .modal--cancel .modal .modal--inner .modal--content .modal--cancel span .modal .modal--inner .modal--content .qr_container .modal .modal--inner .modal--content .qr-info .modal .modal--inner .modal--content .qr-code {
    width: 35rem;
    height: 40rem;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20vh;
  margin: 0 auto;
  padding: 2.5% 0;
  color: #fff;
  background-color: #33A1C4;
}

.footer-one {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 45%;
}

.footer-two {
  border-top: 1px solid #fff;
  height: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.footer-two .socials a {
  margin: 2rem 1rem;
  cursor: pointer;
}

.footer .footer-lists {
  display: flex;
  align-items: center;
}
.footer .footer-lists .footer__list {
  cursor: pointer;
  margin: 0 2rem;
  transition: 0.2s all ease;
  color: #fff;
}

.footer .footer-rights,
.footer .footer__list {
  font-size: 1.6rem;
}

.footer .footer-lists .footer__list:hover,
.footer .footer-lists .footer__list:focus {
  color: #00587E;
}

@media only screen and (min-width: 93.75em) {
  .footer {
    height: 20vh;
  }
  .footer .footer-rights,
.footer .footer__list {
    font-size: 2.2rem;
  }
  .footer-two {
    width: 85%;
  }
  .inner_footer_1 {
    justify-content: flex-start;
    margin-left: 2.5%;
  }
  .inner_footer_2 {
    justify-content: flex-end;
    margin-right: 2.5%;
  }
}
@media only screen and (max-width: 74.875rem) {
  .footer .footer-lists {
    justify-content: center;
  }
  .footer-one {
    padding-bottom: 1.5rem;
  }
  .footer-one :nth-child(1) {
    order: 2;
    padding: 0.75rem;
  }
  .footer-one :nth-child(2) {
    order: 1;
    padding: 0.75rem;
  }
  .footer-one :nth-child(3) {
    order: 3;
    padding: 0.75rem;
  }
}
@media only screen and (max-width: 50rem) {
  .footer .footer-rights,
.footer .footer__list {
    font-size: 1.4rem;
    color: #fff;
  }
  .footer .footer-rights .footer .footer-lists,
.footer .footer__list .footer .footer-lists {
    margin-bottom: 4rem;
  }
  .footer {
    flex-direction: column;
    height: 100%;
  }
  .footer-two {
    padding-top: 2.5rem;
  }
  .footer-two .socials {
    padding-bottom: 1.5rem;
  }
}
.modal {
  z-index: 10000;
}
.modal .modal--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.modal .modal--inner .modal--content {
  background-color: #00587E;
  border: none;
  color: #fff;
}
.modal .modal--inner .modal--content .modal--cancel span {
  width: 3rem;
  height: 0.2rem;
  display: block;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}
.modal .modal--inner .modal--content .modal--cancel span:nth-child(1) {
  -webkit-transform: translateY(0) rotate(-45deg);
          transform: translateY(0) rotate(-45deg);
  margin-bottom: -1px;
}
.modal .modal--inner .modal--content .qr-info {
  color: #FFF8F1;
  font-size: 2.5rem;
  padding: 5rem 0;
  font-weight: 500;
  text-align: center;
}
.modal .modal--inner .modal--content .qr-code {
  width: 24rem;
  height: 30rem;
}
.modal .modal--inner .store-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal .modal--inner .store-inner .store-img {
  width: 70vw;
}
.modal .modal--inner .store-inner .store-img a img {
  width: 100%;
}

.modal--store {
  border-radius: 20px;
  height: 50%;
  display: flex;
  justify-content: center;
}

.form {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form .form-box {
  position: relative;
  width: 70%;
  height: auto;
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  background-color: green;
  border-radius: 0.5rem;
  margin: 20px 0px;
  color: #FFF8F1;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
}
.form .form-box .form-cancel {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.form .form-box .form-cancel span {
  width: 1.5rem;
  height: 0.2rem;
  display: block;
  background-color: #eeeeee;
}
.form .form-box .form-cancel span:nth-child(1) {
  -webkit-transform: translateY(0px) translateX(15px) rotate(-45deg);
          transform: translateY(0px) translateX(15px) rotate(-45deg);
}
.form .form-box .form-cancel span:nth-child(2) {
  -webkit-transform: translateY(0.5px) rotate(45deg);
          transform: translateY(0.5px) rotate(45deg);
}

.form div label {
  display: none;
}

.form div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form div .company-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.form div .company-logo span {
  padding-bottom: 1.5rem;
  font-family: inherit;
  font-size: 1.6rem;
  color: #FFF8F1;
}

.form div input,
select {
  font-family: inherit;
  font-size: 1.6rem;
  padding: 2rem;
  width: 40rem;
  margin-bottom: 20px;
  background-color: #00587E;
  border: solid 1px #b8eaff;
  color: #fff !important;
  border-radius: 3rem;
}

.form div input:focus {
  border: solid 1px #aaefff;
  background-color: #1277a2;
}

.form div select {
  font-family: inherit;
  font-size: 1.6rem;
  padding: 2rem;
  width: 40rem;
  margin-bottom: 20px;
  background-color: #00587E;
  border: solid 1px #b8eaff;
  color: #fff !important;
  border-radius: 3rem;
}

.form .button1 {
  margin: 0.5rem;
  padding: 1.5rem 4rem;
  border-radius: 2rem;
  background: #0283bb;
  color: #ffffff;
  font-family: inherit;
  font-size: 1.6rem;
  transition: 0.2s all ease;
  border: none;
  width: auto;
}

.form .buttonSub {
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 22.5px;
  border-radius: 2rem;
  background: #0283bb;
  color: #ffffff;
  font-family: inherit;
  font-size: 1.6rem;
  transition: 0.2s all ease;
  border: none;
}

.form .button2 {
  margin: 0.5rem;
  padding: 1.5rem 4rem;
  border-radius: 2rem;
  background: #ddd;
  color: #00587E;
  font-family: inherit;
  font-size: 1.6rem;
  transition: 0.2s all ease;
  border: none;
  width: auto;
}

.text-container {
  background-image: url(/static/media/wota-div-bg.5f46b3a4.svg);
  background-repeat: no-repeat;
  background-position: center;
}
.text-container h2 {
  z-index: 1000;
}
.text-container p {
  z-index: 1000;
}

.wota-div-bg {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 35rem;
  z-index: 1;
}

::-webkit-file-upload-button {
  color: #fff;
  background: #00587E;
  cursor: pointer;
  white-space: nowrap;
  width: 100px;
  border: 25px solid #00587E;
  border-radius: 25px;
}

::-ms-browse {
  color: #fff;
  background: #00587E;
  cursor: pointer;
  white-space: nowrap;
  width: 100px;
  border: 25px solid #00587E;
  border-radius: 25px;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(184, 234, 255, 0.4588235294);
  opacity: 1;
  /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(184, 234, 255, 0.4588235294);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(184, 234, 255, 0.4588235294);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(184, 234, 255, 0.4588235294);
}

@media only screen and (max-width: 560px) {
  .form div input {
    width: 70%;
  }
  .form div select {
    width: 70%;
  }
  .form div .company-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 770px) {
  .modal .modal--inner .store-inner .qr-info {
    text-align: center;
    font-size: 2.5rem;
    margin: 2rem 0;
  }
  .modal .modal--inner .store-inner .store-img {
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal .modal--inner .store-inner .store-img a img {
    width: 70%;
  }
}
@media only screen and (max-width: 350px) {
  .form div input {
    width: 80%;
  }
  .form div select {
    width: 80%;
  }
  .form .row {
    flex-direction: column;
  }
  .form .form-box .form-cancel {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .form .form-box .form-cancel span {
    width: 1.5rem;
    height: 0.2rem;
    display: block;
    background-color: #eeeeee;
  }
  .form .form-box .form-cancel span:nth-child(1) {
    -webkit-transform: translateY(0px) translateX(15px) rotate(-45deg);
            transform: translateY(0px) translateX(15px) rotate(-45deg);
  }
  .form .form-box .form-cancel span:nth-child(2) {
    -webkit-transform: translateY(0.5px) rotate(45deg);
            transform: translateY(0.5px) rotate(45deg);
  }
}
/**** ANIMS ****/
.fade-in-in {
  -webkit-animation: fadeInIn 1.75s;
          animation: fadeInIn 1.75s;
}

.appear-in {
  -webkit-animation: opacityIn 2s;
          animation: opacityIn 2s;
}

@-webkit-keyframes fadeInIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(55px);
            transform: translateY(55px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes fadeInIn {
  from {
    opacity: 0;
    -webkit-transform: translateY(55px);
            transform: translateY(55px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@-webkit-keyframes opacityIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes opacityIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=App.css.map */
.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}
@-webkit-keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}
@keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}
